import { DataGrid, GridRowsProp, GridColDef, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useState } from "react"
import Status from '../../Components/Status';
import { Event } from '../Home';
import moment from 'moment';
import { Employee } from '../../App';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Sickness } from './HrSickness';

interface Props {
    events: Event[]
    emps: Employee[]
    sickness: Sickness[]
}

const HrGraph = ({ events, emps, sickness }: Props) => {
    const [lineData, setLineData] = useState<{
        name: string,
        cOffice: number,
        cSite: number,
        directors: number,
        fAndW: number,
        rem: number,
        ropeAccess: number,
        tIOps: number,
        tISales: number,
        tISite: number,
        trainAndPPE: number,
        misc: number,
        process: number,
        sales: number

    }[]>([
        {
            name: "Jan",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Feb",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Mar",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Apr",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "May",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Jun",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Jul",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Aug",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Sep",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Oct",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Nov",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Dec",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
    ])

    const [all, setAll] = useState<{
        name: string,
        cOffice: number,
        cSite: number,
        directors: number,
        fAndW: number,
        rem: number,
        ropeAccess: number,
        tIOps: number,
        tISales: number,
        tISite: number,
        trainAndPPE: number,
        misc: number,
        process: number,
        sales: number

    }[]>([
        {
            name: "Jan",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Feb",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Mar",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Apr",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "May",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Jun",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Jul",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Aug",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Sep",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Oct",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Nov",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
        {
            name: "Dec",
            cOffice: 0,
            cSite: 0,

            directors: 0,
            fAndW: 0,

            rem: 0,
            ropeAccess: 0,
            tIOps: 0,
            tISales: 0,
            tISite: 0,
            trainAndPPE: 0,
            misc: 0,
            process: 0,
            sales: 0
        },
    ])
    const getDepartmentKey = (dep: string) => {
        if (dep === "Contracts - Office") {
            return "cOffice"
        }
        if (dep === "Contracts - Site") {
            return "cSite"
        }
        if (dep === "Design") {
            return "cOffice"
        }
        if (dep === "Directors") {
            return "directors"
        }
        if (dep === "Sales") {
            return "sales"
        }
        if (dep === "Process") {
            return "process"
        }
        if (dep === "Finance & Warehouse") {
            return "fAndW"
        }
        if (dep === "Remedials - FPS") {
            return "rem"
        }
        if (dep === "Remedials - LPS") {
            return "rem"
        }
        if (dep === "Rope Access") {
            return "ropeAccess"
        }
        if (dep === "Test & Inspection Operations") {
            return "tIOps"
        }
        if (dep === "Test & Inspection Sales") {
            return "tISales"
        }
        if (dep === "Test & Inspection Site") {
            return "tISite"
        }
        if (dep === "Training & PPE") {
            return "trainAndPPE"
        }
        return "misc"
    }
    useEffect(() => {
        const temp = [
            {
                name: "Jan",
                cOffice: 0,
                cSite: 0,

                directors: 0,
                fAndW: 0,

                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Feb",
                cOffice: 0,
                cSite: 0,

                directors: 0,
                fAndW: 0,

                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Mar",
                cOffice: 0,
                cSite: 0,

                directors: 0,
                fAndW: 0,

                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Apr",
                cOffice: 0,
                cSite: 0,

                directors: 0,
                fAndW: 0,

                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "May",
                cOffice: 0,
                cSite: 0,

                directors: 0,
                fAndW: 0,

                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Jun",
                cOffice: 0,
                cSite: 0,

                directors: 0,
                fAndW: 0,

                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Jul",
                cOffice: 0,
                cSite: 0,

                directors: 0,
                fAndW: 0,

                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Aug",
                cOffice: 0,
                cSite: 0,

                directors: 0,
                fAndW: 0,

                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Sep",
                cOffice: 0,
                cSite: 0,

                directors: 0,
                fAndW: 0,

                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Oct",
                cOffice: 0,
                cSite: 0,

                directors: 0,
                fAndW: 0,

                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Nov",
                cOffice: 0,
                cSite: 0,

                directors: 0,
                fAndW: 0,

                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Dec",
                cOffice: 0,
                cSite: 0,

                directors: 0,
                fAndW: 0,

                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
        ]
        const list = ["Medical", "Paid Sickness", "Unpaid Sickness"];
        sickness.filter((ev) => list.includes(ev.type)).map((event) => {
            const department = emps.filter((emp) => emp.email.toLowerCase() === event.user.email.toLowerCase())[0].department;

            // Check if the event.start is in 2024
            if (moment(event.start).year() === 2024) {
                temp.map((data) => {
                    if ((data.name === moment(event.start).format("MMM") && event.end) && department) {
                        const date1 = moment(event.start);
                        const date2 = moment(event.end);
                        if (date2.diff(date1, 'days') < 1) {
                            console.log(date2.diff(date1, 'days'), event)
                        }
                        data[getDepartmentKey(department)] = data[getDepartmentKey(department)] + date2.diff(date1, 'days') + 1;
                    }
                });
            }
        });
        setLineData(temp)
    }, [])

    return (
        <div className='graph-dim'>
            <ResponsiveContainer width={"100%"} height={"100%"}>
                <LineChart
                    data={lineData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line name="Contracts - Office" dataKey="cOffice" stroke="#003f5c" />
                    <Line name="Contracts - Site"  dataKey="cSite" stroke="#2f4b7c" />
                    <Line name="Directors"  dataKey="directors" stroke="#a05195" />
                    <Line name="Finance & Warehouse"  dataKey="fAndW" stroke="#d45087" />
                    <Line name="Remedials"  dataKey="rem" stroke="#ff7c43" />
                    <Line name="Rope Access"  dataKey="ropeAccess" stroke="#ffa600" />
                    <Line name="T&I Operations"  dataKey="tIOps" stroke="#ff47b1" />
                    <Line name="T&I Sales"  dataKey="tISales" stroke="#de56d6" />
                    <Line name="T&I Site"  dataKey="tISite" stroke="#9d69f2" />
                    <Line name="Training & PPE"  dataKey="trainAndPPE" stroke="#0078ff" />
                    <Line name="Process"  dataKey="process" stroke="#00e9c0" />
                    <Line name="Sales"  dataKey="sales" stroke="#00ff32" />

                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default HrGraph