import React from 'react';
import './Styles/index.scss';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import data from './Data/data.json';
import { doc, getFirestore, setDoc } from "firebase/firestore";

export interface Employee {
  fullName: string;
  startDate: string;
  email: string;
  approver: string;
  access: string[];
  entitlement: number;
  daysForService: number;
  xmas: number;
  department?: string;
  daysToUse: number;
  carryOver: number;
  inLieu: number;
}

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  );
}

export default App;
